import React, { Component } from 'react';
import {Row, Col, Button, Modal, Form, Panel} from 'react-bootstrap';
import InputText from '../common_components/InputText';
import DataDropdown from '../common_components/DataDropdown';
import DataGrid from '../common_components/DataGrid.js';
import {mapArrayToObject, convertTimestampToDate, getAPI, postAPI, resetObject, validateForm, constants, getLoggedInUser, showFormValidationAlert, getCurrencySymbol} from '../common_components/common.js';
import '../FormPage.css';
import Config from '../Config';
import I18n from '../translations/myinkiq-i18n.js';
const { Translate, Localize } = require('react-i18nify');

class DeleteConfirmation extends Component {
	onDelete() {
		this.props.onConfirm();
		this.props.onClose();	
	}

	render() {
		return(<Modal show={this.props.visible}>
			<Modal.Body><Translate value='components.labels.deleteUserConfirmationPrompt' /></Modal.Body>
			<Modal.Footer>
				<Button bsStyle='danger' onClick={() => this.onDelete()}><Translate value='components.buttons.delete' /></Button>
				<Button onClick={this.props.onClose}><Translate value='components.buttons.cancel' /></Button>
			</Modal.Footer>
		</Modal>);
	}
}

class InactivateConfirmation extends Component {
	onInactivate() {
		this.props.onConfirm();
		this.props.onClose();
	}

	render() {
		return(<Modal show={this.props.visible}>
			<Modal.Body><Translate value='components.labels.inactivateUserConfirmationPrompt' /></Modal.Body>
			<Modal.Footer>
				<Button bsStyle='danger' onClick={() => this.onInactivate()}><Translate value='components.buttons.inactivate' /></Button>
				<Button onClick={this.props.onClose}><Translate value='components.buttons.cancel' /></Button>
			</Modal.Footer>
		</Modal>);
	}
}


const validationFields = {
	userTypeId: "required",
	firstName: "required",
	lastName: "required",
	emailAddress: "required",
	displayName: "required",
	userName: "required",
	password: "required",
	confirmNewPassword: "",
};

const userRolesAccess = {
	[constants.vip_type_id]: {
		// Can View
		chkViewAccess: [],
		//	Can Edit
		chkEditAccess: [
			'accessCustomersCode', 
			'accessComponentsCode', 
			'accessFormulasCode', 
			'accessFormulaPricingCode', 
			'accessInventoryCode', 
			'accessPOCode', 
			'accessOrdersCode', 
			'accessVendorsCode', 
			'accessShippersCode'
		],
		// Report Access
		selectedUserReports: '*',
		formData: {
			// User Rights
			accountingFlag: 1, 
			confirmSOFlag: 1, 
			userMaintenanceFlag: 1, 
			userMaintenanceCustomerFlag: 1, 
			userMaintenanceAccessFlag: 1, 
			editCompanyFlag: 1, 
			formulaPricingBreakdownFlag: 1, 
			formulaPricingApprovalFlag: 1, 
			userMaintenanceRightsFlag: 1, 
			userMaintenanceReportsFlag: 1, 
			formulaVisibleToCustomerFlag : 1,
			shipmentOutlierAccessFlag: 1,
			adminFlag: 1, accessSetTarget: 1, 
			performReadsFlag: 1, 
			// Customer
			allCustomerAccessFlag: 1, 
			// Color Connexion
			jobInterfaceFlag: 1, 
			createColorFlag: 1, 
			accessSetTarget: 1, 
			accessDelete: 1, 
			maintainJobFlag: 1, 
			deactivateFormulaFlag: 1, 
			performReadsFlag: 1, 
			notesFlag: 1, 
			admin: 1,
			// instrumentsDisabledFlag: 1
		},
	},
	[constants.rnd_type_id]: {
		// Can View
		chkViewAccess: [
			'accessFormulaPricingCode', 
			'accessInventoryCode', 
			'accessPOCode', 
			'accessShippersCode'
		],
		//	Can Edit
		chkEditAccess: [
			'accessCustomersCode', 
			'accessComponentsCode', 
			'accessFormulasCode', 
			'accessOrdersCode', 
			'accessVendorsCode'
		],
		// Report Access
		selectedUserReports: {
			'Customer Reports': [constants.blending_ticket_report_id, constants.lbs_sold_report_id],
			'Formula Reports': [constants.component_cost_price_change_report_id, constants.contains_components_report_id,constants.formula_cost_price_change_report_id, constants.formula_cost_report_id],
			'Sales Order Reports': [constants.batch_ticket_report_id, constants.invoice_report_id, constants.lab_request_report_id, constants.sales_order_report_id],
			'Shipping Reports': [constants.bill_of_lading_report_id, constants.bucket_label_report_id, constants.late_loss_damage_for_purchase_order_report_id, constants.packing_slip_report_id],
		},
		formData: {
			// User Rights
			confirmSOFlag: 1,
			// Customer	
			allCustomerAccessFlag: 1, 
			// Color Connexion
			jobInterfaceFlag: 1, 
			// createColorFlag: 1, 
			accessSetTarget: 1, 
			accessDelete: 1, 
			maintainJobFlag: 1, 
			deactivateFormulaFlag: 1, 
			performReadsFlag: 1
		},
	},
	[constants.finance_type_id]: {
		// Can View
		chkViewAccess: [
			'accessFormulaPricingCode', 
		],
		//	Can Edit
		chkEditAccess: [
			'accessCustomersCode', 
			'accessComponentsCode', 
			'accessFormulasCode', 
			'accessOrdersCode',
			'accessInventoryCode', 
			'accessPOCode', 
			'accessVendorsCode',
			'accessShippersCode'
		],
		// Report Access
		selectedUserReports: {
			'Accounting Reports': '*',
			'Customer Reports': [constants.blending_ticket_report_id, constants.lbs_sold_report_id],
			'Formula Reports': [constants.batch_variance_id, constants.component_cost_price_change_report_id, constants.contains_components_report_id,constants.formula_cost_price_change_report_id, constants.formula_cost_report_id],
			'Sales Order Reports': [constants.batch_ticket_report_id, constants.invoice_report_id, constants.lab_request_report_id, constants.sales_order_report_id],
			'Shipping Reports': '*',
		},
		formData: {
			// User Rights
			accountingFlag: 1,
			confirmSOFlag: 1,
			userMaintenanceFlag: 1,
			// Customer	
			allCustomerAccessFlag: 1, 
			// Color Connexion
		},
	},
	[constants.labtech_type_id]: {
		// Can View
		chkViewAccess: [
			'accessComponentsCode', 
			'accessFormulaPricingCode', 
		],
		//	Can Edit
		chkEditAccess: [
			'accessCustomersCode', 
			'accessFormulasCode', 
			'accessOrdersCode',
			'accessShippersCode'
		],
		// Report Access
		selectedUserReports: {
			'Customer Reports': [constants.blending_ticket_report_id],
			'Formula Reports': [constants.formula_cost_report_id],
			'Sales Order Reports': [constants.batch_ticket_report_id, constants.lab_request_report_id, constants.sales_order_report_id],
			'Shipping Reports': [constants.bucket_label_report_id],
		},
		formData: {
			// User Rights
			confirmSOFlag: 1,
			// Customer	
			allCustomerAccessFlag: 1, 
			// Color Connexion
			jobInterfaceFlag: 1, 
			// createColorFlag: 1, 
			accessSetTarget: 1, 
			accessDelete: 1, 
			maintainJobFlag: 1, 
			deactivateFormulaFlag: 1, 
			performReadsFlag: 1
		},
	},
	[constants.sales_type_id]: {
		// Can View
		chkViewAccess: [
			'accessFormulasCode',
			'accessFormulaPricingCode', 
		],
		//	Can Edit
		chkEditAccess: [
			'accessCustomersCode', 
			'accessOrdersCode',
		],
		// Report Access
		selectedUserReports: {
			'Customer Reports': [constants.blending_ticket_report_id, constants.color_match_report_id, constants.historical_report_id, constants.lbs_sold_report_id, ],
			'Formula Reports': [constants.formula_cost_report_id],
			'Sales Order Reports': [constants.batch_ticket_report_id, constants.lab_request_report_id, constants.sales_order_report_id],
			'Shipping Reports': [constants.bill_of_lading_report_id, constants.bucket_label_report_id, constants.packing_slip_report_id],
		},
		formData: {
			// User Rights
			// confirmSOFlag: 1,
			// Customer	
			// allCustomerAccessFlag: 1, 
			// Color Connexion
			jobInterfaceFlag: 1, 
			// createColorFlag: 1, 
			accessSetTarget: 1, 
			performReadsFlag: 1,
			accessDelete: 1,  
			deactivateFormulaFlag: 1, 
		},
	},
	[constants.admin_type_id]: {
		// Can View
		chkViewAccess: [
			'accessFormulaPricingCode', 
			'accessInventoryCode',
		],
		//	Can Edit
		chkEditAccess: [
			'accessCustomersCode', 
			'accessComponentsCode', 
			'accessFormulasCode', 
			'accessOrdersCode',
			'accessVendorsCode',
			'accessShippersCode'
		],
		// Report Access
		selectedUserReports: {
			'Accounting Reports': [constants.purchase_order_report_id],
			'Customer Reports': [constants.blending_ticket_report_id, constants.lbs_sold_report_id, ],
			'Formula Reports': [constants.formula_cost_report_id],
			'Sales Order Reports': [constants.batch_ticket_report_id, constants.invoice_report_id, constants.lab_request_report_id, constants.sales_order_report_id],
			'Shipping Reports': [constants.bill_of_lading_report_id, constants.bucket_label_report_id, constants.late_loss_damage_for_sales_order_report_id, constants.packing_slip_report_id],
		},
		formData: {
			// User Rights
			accountingFlag: 1,
			confirmSOFlag: 1,
			// Customer	
			// allCustomerAccessFlag: 1, 
			// Color Connexion
			jobInterfaceFlag: 1, 
			// createColorFlag: 1, 
			accessSetTarget: 1, 
			performReadsFlag: 1,
			accessDelete: 1,  
			deactivateFormulaFlag: 1, 
		},
	},
	[constants.sales_manager_type_id]: {
		// Can View
		chkViewAccess: [
			'accessFormulaPricingCode', 
		],
		//	Can Edit
		chkEditAccess: [
			'accessCustomersCode', 
			'accessFormulasCode', 
			'accessOrdersCode',
		],
		// Report Access
		selectedUserReports: {
			'Customer Reports': '*',
			'Formula Reports': [constants.formula_cost_report_id],
			'Sales Order Reports': [constants.batch_ticket_report_id, constants.invoice_report_id, constants.lab_request_report_id, constants.sales_order_report_id],
			'Shipping Reports': [constants.bill_of_lading_report_id, constants.bucket_label_report_id, constants.packing_slip_report_id],
		},
		formData: {
			// User Rights
			confirmSOFlag: 1,
			userMaintenanceFlag: 1,
			userMaintenanceCustomerFlag: 1,
			userMaintenanceAccessFlag: 1,
			userMaintenanceRightsFlag: 1,
			userMaintenanceReportsFlag: 1,
			formulaVisibleToCustomerFlag: 1,
			shipmentOutlierAccessFlag: 0,
			// Customer	
			allCustomerAccessFlag: 1, 
			// Color Connexion
			jobInterfaceFlag: 1, 
			createColorFlag: 1, 
			accessSetTarget: 1, 
			accessDelete: 1, 
			maintainJobFlag: 1, 
			deactivateFormulaFlag: 1, 
			performReadsFlag: 1, 
			notesFlag: 1, 
			admin: 1,
			// instrumentsDisabledFlag: 1
		},
	},
	[constants.customer_type_id]: {
		// Can View
		chkViewAccess: [
			'accessFormulasCode', 
		],
		//	Can Edit
		chkEditAccess: [
			'accessOrdersCode',
		],
		// Report Access
		selectedUserReports: {
			'Customer Reports': [constants.blending_ticket_report_id, constants.historical_report_id, constants.lbs_sold_report_id],
			'Sales Order Reports': [constants.invoice_report_id, constants.lab_request_report_id, constants.sales_order_report_id],
		},
		formData: {
			// User Rights
			userMaintenanceFlag: 1,
			userMaintenanceAccessFlag: 1,
			// Customer	
			// Color Connexion
			jobInterfaceFlag: 1, 
			createColorFlag: 1, 
			accessSetTarget: 1, 
			accessDelete: 1, 
			maintainJobFlag: 1, 
			deactivateFormulaFlag: 1, 
			performReadsFlag: 1, 
			notesFlag: 1, 
			admin: 1,
			// instrumentsDisabledFlag: 1
		},
	}
};


class UserForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: "New User",
			isBillingInfoSameAsContactInfo: false,
			errors: resetObject(validationFields),
			formData: {
				id: 0,
				lastLoginDate: null,
				accessComponentsCode: 'N',
				accessCustomersCode: 'N',
				accessFormulasCode: 'N',
				accessFormulaPricingCode: 'N',
				accessInventoryCode: 'N',
				accessOrdersCode: 'N',
				accessPOCode: 'N',
				accessShippersCode: 'N',
				accessVendorsCode: 'N',

				accountingFlag: false, // Accounting functions
				confirmSOFlag: false, // Confirm Sales Order
				editCompanyFlag: false, // Edit Company Information
				formulaPricingBreakdownFlag: false, // View Formula Price Breakdown
				formulaPricingApprovalFlag: false, // Approve Formula Price
				userMaintenanceFlag: false,	// User Maintenance 
				userMaintenanceRightsFlag: false,	// User Maintenance Rights
				userMaintenanceCustomerFlag: false,// User Maintenance Customer
				userMaintenanceAccessFlag: false,// User Maintenance Access
				userMaintenanceReportsFlag: false,// User Maintenance Reports
				formulaVisibleToCustomerFlag: false,
				shipmentOutlierAccessFlag: false,
				
				displayName: "",
				emailAddress: "",
				firstName: "",
				lastName: "",
				userName: "",
				
				allCustomerAccessFlag: false, // 
				jobInterfaceFlag: false, // Allow Create/edit jobs
				createColorFlag: false, // Allow Create Color
				accessSetTarget: 'N',
				accessDelete: 'N',	// Allow Job/color delete
				maintainJobFlag: false, // 
				deactivateFormulaFlag: false, // Allow deactivation of formulas
				performReadsFlag: false, // Allow Perform Reads
				notesFlag: false,	// Allow Job/Note creation
				adminFlag: false,	// Admin
				instrumentsDisabledFlag: false,	// Allow instruments settings disabled
				inkQualificationQCFlag: false,	// Allow QC mode
				
				password: "",
				requireFlag: false,
				spanishFlag: false,
				
				userName: "",
				userTypeId: null,
				retired: false,
				userImageLocation: null,
				userImageType: null,
				
				model:"com.phototype.myinkiq.model.vos.dtos::UsersDto",
				customerId: 0,
				customerName: null,
				hasBlendingFormulas: false,
				name: null,
				passwordChanged: true,
			},
			chkViewAccess: {
				accessComponentsCode: false,
				accessCustomersCode: false,
				accessFormulasCode: false,
				accessFormulaPricingCode: false,
				accessInventoryCode: false,
				accessOrdersCode: false,
				accessPOCode: false,
				accessShippersCode: false,
				accessVendorsCode: false,
			},
			chkEditAccess: {
				accessComponentsCode: false,
				accessCustomersCode: false,
				accessFormulasCode: false,
				accessFormulaPricingCode: false,
				accessInventoryCode: false,
				accessOrdersCode: false,
				accessPOCode: false,
				accessShippersCode: false,
				accessVendorsCode: false,
			},
			salesBudget: {
				airline: null,
				lodging: null,
				restaurant: null,
				carParking: null,
				autoExpense: null,
				parkingAndOther: null,
				cellPhone: null,
				misc: null,
			},
			confirmNewPassword: "",
			userTypesList: [],
			months: null,
			reportsList: [],
			reports: [],
			emailList:[],
			initEmailList: [],
			userEmailList:[],
			bEmailAccessChange: false,
			
			userLoginCount: 0,
			userCustomers: [],
			userReports: [],
			
			selectedUserCustomers: [], // 
			selectedUserReports: {},
			chkAllViewAccess: false,
			chkAllEditAccess: false,
			chkAllReportsAccess: false,
			chkAllRightsAccess: false,
			chkAllColorConnexionAccess: false,
			
			yearlyBudgets: [],
			yearlyBudgetColumns: ['Year', 'Sales Goal', 'Expense Budget', 'Prospect Calls/Month'],
			yearlyBudgetFields: ['year', 'totalSalesGoal', 'totalExpenseBudget', 'prospectCallsPerMonth'],
			yearlyBudgetFormatColumns: {
				1: {currency: true},
				2: {currency: true}
			},
			deletedYearlyBudgetIds: [],
			editBudgetRow: null,
			selectedBudgetRowIndex: null,
			bShowBudgetForm: false,
			
			monthlyExpenditures: [],
			monthlyExpenditureColumns: ['Month', 'Year', 'Amount'],
			monthlyExpenditureFields: ['monthName', 'year', 'totalAmount'],
			monthlyExpendituresFormatColumns: {
				3: {currency: true}
			},
			deletedMonthlyExpenditureIds: [],
			editExpenditureRow: null,
			selectedExpenditureRowIndex: null,
			bShowExpenditureForm: false,
			
			monthlyProspectCalls: [],
			monthlyProspectCallColumns: ['Month', 'Year', 'Calls'],
			monthlyProspectCallFields: ['monthName', 'year', 'numCalls'],
			monthlyProspectCallFormatColumns: {
				3: {currency: true}
			},
			deletedMonthlyProspectCallIds: [],
			editProspectCallRow: null,
			selectedProspectCallRowIndex: null,
			bShowProspectCallForm: false,

		};
		this.handleInputChanged = this.handleInputChanged.bind(this);
		this.onUserProgramsViewAccess = this.onUserProgramsViewAccess.bind(this);
		this.onUserProgramsEditAccess = this.onUserProgramsEditAccess.bind(this);
		this.onUserReportsAccess = this.onUserReportsAccess.bind(this);
		this.onUserCustomersAccess = this.onUserCustomersAccess.bind(this);
		this.onUserEmailsAccess = this.onUserEmailsAccess.bind(this);
		
		this.onSave = this.onSave.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.onInactivate = this.onInactivate.bind(this);
		
		this.onCheckAllAccess = this.onCheckAllAccess.bind(this);
		this.isSalesPerson = this.isSalesPerson.bind(this);
		this.isLabTech = this.isLabTech.bind(this);
		
		this.onAddBudget = this.onAddBudget.bind(this);
		this.onRemoveBudget = this.onRemoveBudget.bind(this);
		this.editBudget = this.editBudget.bind(this);
		this.budgetRowSelected = this.budgetRowSelected.bind(this);
		this.saveYearlyBudget = this.saveYearlyBudget.bind(this);
		
		this.onAddExpenditure = this.onAddExpenditure.bind(this);
		this.onRemoveExpenditure = this.onRemoveExpenditure.bind(this);
		this.editExpenditure = this.editExpenditure.bind(this);
		this.expenditureRowSelected = this.expenditureRowSelected.bind(this);
		this.saveMonthlyExpenditure = this.saveMonthlyExpenditure.bind(this);
		this.refreshMonthlyExpenditures = this.refreshMonthlyExpenditures.bind(this);
		this.filterEmailList = this.filterEmailList.bind(this);
		
		this.onAddProspectCall = this.onAddProspectCall.bind(this);
		this.onRemoveProspectCall = this.onRemoveProspectCall.bind(this);
		this.editProspectCall = this.editProspectCall.bind(this);
		this.prospectCallRowSelected = this.prospectCallRowSelected.bind(this);
		this.saveMonthlyProspectCall = this.saveMonthlyProspectCall.bind(this);
		this.refreshMonthlyProspectCalls = this.refreshMonthlyProspectCalls.bind(this);
		this.onUserRoleSelected = this.onUserRoleSelected.bind(this);

	}
	
	filterEmailList(userTypeId) {
		var emailList = this.state.initEmailList;
		if(userTypeId == constants.vip_type_id) {
			emailList = emailList.filter(obj => obj.name.indexOf("Sales Rep") == -1 && obj.name.indexOf("Sales Manager") == -1);
		} else if(userTypeId == constants.sales_manager_type_id) {
			emailList = emailList.filter(obj => obj.name.indexOf("Sales Rep") == -1 && obj.name.indexOf("VIP") == -1);
		} else if(userTypeId == constants.sales_type_id) {
			emailList = emailList.filter(obj => obj.name.indexOf("VIP") == -1 &&  obj.name.indexOf("Sales Manager") == -1);
		}
		
		this.setState({emailList: emailList});
	}
	
	onUserRoleSelected(userTypeId) {
		
		if(userRolesAccess[userTypeId] != undefined) {
			
			var chkViewAccess = []; // this.state.chkViewAccess;
			var chkEditAccess = []; // this.state.chkEditAccess;
			var formData = this.state.formData;
			var selectedUserReports = this.state.selectedUserReports;
			
			const permissions = userRolesAccess[userTypeId];
			
			// chkViewAccess
			for(const i in permissions['chkViewAccess']) {
				let chk = permissions['chkViewAccess'][i];
				chkViewAccess[chk] = true;
				formData[chk] = 'V';
			}
			
			// chkEditAccess
			for(const i in permissions['chkEditAccess']) {
				let chk = permissions['chkEditAccess'][i];
				chkEditAccess[chk] = true;
				formData[chk] = 'E';
			}
			
			if(permissions['selectedUserReports'] == '*') {
				this.state.reports.forEach(report => {
					selectedUserReports[report.id] = true;
				});
			} else {
				for(const category in permissions['selectedUserReports']) {
					let reports = permissions['selectedUserReports'][category];
					// selectedUserReports
					if(reports == '*') {
						reports = this.state.reports.filter(row => row.category == category).map(obj => obj.id);
					}
					// console.log("reports: ", reports);
					for(const j in reports)
						selectedUserReports[reports[j]] = true;
				}
			}
			
			// Resetting 
			const booleanFieldsToReset = ['accountingFlag', 'confirmSOFlag', 'editCompanyFlag', 'formulaPricingBreakdownFlag', 'formulaPricingApprovalFlag', 'userMaintenanceFlag', 'userMaintenanceRightsFlag', 'userMaintenanceCustomerFlag', 'userMaintenanceAccessFlag', 'userMaintenanceReportsFlag', 'formulaVisibleToCustomerFlag', 'shipmentOutlierAccessFlag', 'allCustomerAccessFlag', 'jobInterfaceFlag', 'createColorFlag', 'maintainJobFlag', 'deactivateFormulaFlag', 'performReadsFlag', 'notesFlag', 'adminFlag', 'instrumentsDisabledFlag', 'inkQualificationQCFlag'];
			for(const i in booleanFieldsToReset) {
				const fieldName = booleanFieldsToReset[i];
				formData[fieldName] = permissions['formData'][fieldName] != undefined ? true : false;
			}
			
			
			const strFieldsToReset = ['accessComponentsCode', 'accessCustomersCode', 'accessFormulasCode', 'accessFormulaPricingCode', 'accessInventoryCode', 'accessOrdersCode', 'accessPOCode', 'accessShippersCode', 'accessVendorsCode', 'accessSetTarget', 'accessDelete', ];
			for(const i in strFieldsToReset) {
				const fieldName = strFieldsToReset[i];
				// formData[fieldName] = permissions['formData'][fieldName] != undefined ? 'Y' : 'N';
				// Samee - bug fix
				formData[fieldName] = permissions['chkEditAccess'].indexOf(fieldName) >= 0 ? "E" : 
					(permissions['chkViewAccess'].indexOf(fieldName) >= 0 ? "V" : "N");
			}
			// console.log("permissions['formData']: ", permissions['formData'], ", formData: ", formData);

			
			this.setState({
				chkViewAccess: chkViewAccess,
				chkEditAccess: chkEditAccess,
				formData: formData,
				selectedUserReports: selectedUserReports,
			});
		}
	}

	componentDidMount() {
		let formData = this.state.formData;
		let loggedInUser = getLoggedInUser();
		let selectedUserCustomers = this.state.selectedUserCustomers;
		
		//If the user is a customer creating a new user default to customer type.
		if (loggedInUser.userType == "Customer") {
			selectedUserCustomers[0] = {value: 0, name: ''};
			formData.userTypeId = 7;//customer user type ID
			selectedUserCustomers[0].value = loggedInUser.customerId;
			selectedUserCustomers[0].name = loggedInUser.customerName;

		}
		// console.log("customerId " + formData.customerId );
		// console.log("customerName " + formData.customerName );
		getAPI("users/types").then(resUserTypes => {
			getAPI("reports/list").then(resReportsList => {
				// console.log("resReportsList: ", resReportsList);
				let objReportsList = {};
				for(const i in resReportsList['data']) {
					const report = resReportsList['data'][i];
					const category = report['category'];
					if(objReportsList[category] == undefined)
						objReportsList[category] = [];
					objReportsList[category].push(report);
				}
				// Discard the LBs Sold Report Category since that report is already under Customers
				// delete objReportsList["LBS Sold Reports"];
				const reportsList = [];
				for(const category in objReportsList) {
					reportsList.push({'category': category, 'reports': objReportsList[category]})
				}
				if(this.props.editUserId) {
					formData.password = "";
					validationFields['password'] = "";
				}
				this.setState({
					formData: formData,
					userTypesList: resUserTypes['data'],
					reportsList: reportsList,
					reports: resReportsList['data'],
					selectedUserCustomers: selectedUserCustomers,
				});
			});

			getAPI("/emails/emaillist").then(emailList => {
				var emailListData = emailList['data'];
				// console.log("emailListData: ", emailListData);
				this.setState({
					initEmailList: emailListData 
				});
			});
            const userId = this.props.editUserId;
			if(userId > 0) {
				getAPI("emails/useremails?userId="+ userId).then(userEmailList => {
					userEmailList = userEmailList['data'].map(userEmailLists => ({value: userEmailLists.emailId, label: userEmailLists.emailName}))
					this.setState({
						userEmailList: userEmailList
					});
				});
			}

			if(this.props.editUserId) {
				const userId = this.props.editUserId;
				
				getAPI("users/" + userId).then(resUser => {
					formData = resUser['data'][0];
					let urlUserCustomers = "users/" + userId + "/customers";
					if(formData.userType == "Sales" || formData.userType == "Sales Manager")
						urlUserCustomers = "users/" + userId + "/salesrepcustomers";
					// console.log("formData.userType: ", formData.userType, ", urlUserCustomers: ", urlUserCustomers);
					getAPI("users/logins/" + userId).then(resLogin => {
						getAPI("users/reports/" + userId).then(resUserReports => {
							getAPI(urlUserCustomers).then(resUserCustomers => {
								let chkViewAccess = this.state.chkViewAccess;
								let chkEditAccess = this.state.chkEditAccess;
								for(const accessField in chkViewAccess) {
									if(formData[accessField] == 'E')
									{
										chkEditAccess[accessField] = true;
									}
									else if(formData[accessField] == 'V')
									{
										chkViewAccess[accessField] = true;
									}
								}
								const selectedUserCustomers = resUserCustomers['data'].map(obj => {
									const customerRow = this.props.activeCustomers.filter(customer => customer.id == obj.customerId)[0];
									
									
									let customerName = customerRow ? customerRow['name'] : '';
									return ({
										value: obj.customerId, 
										label: customerName,
									})
									}
								);
								const selectedUserReports = {};
								for(const i in resUserReports['data']) {
									const userReport = resUserReports['data'][i];
									const reportId = userReport['reportId'];
									selectedUserReports[reportId] = true;
								}
								const userLoginCount = resLogin['data'][0] ? 
									resLogin['data'][0]['loginCount'] : this.state.userLoginCount;
								
								formData.password = "";
								validationFields['password'] = "";
								this.filterEmailList(formData.userTypeId);
								
								this.setState({
									formData: formData,
									userLoginCount: userLoginCount,
									userReports: resUserReports['data'],
									userCustomers: resUserCustomers['data'],
									selectedUserReports: selectedUserReports,
									selectedUserCustomers: selectedUserCustomers,
									chkViewAccess: chkViewAccess,
									chkEditAccess: chkEditAccess,
									title: "User Id: " + userId,
								});
							});
						});
					});
					
					// Fetch yearly budgets in case of a sales person/manager/vip
					if(this.isSalesPerson(formData.userTypeId) || this.isLabTech(formData.userTypeId)) {
						getAPI("budgets/" + userId + "/list").then(resYearlyBudgets => {
							this.refreshMonthlyExpenditures(userId);
							this.refreshMonthlyProspectCalls(userId);
							this.setState({
								yearlyBudgets: resYearlyBudgets['data'],
							});
						
						});	
					}
				});
				
			}
			
		});

	}
	
	isSalesPerson(userTypeId) {
		return userTypeId == 8 || userTypeId == 9 || userTypeId == 170;
	}
	
	isLabTech(userTypeId) {
		return userTypeId == constants.labtech_type_id;
	}
	
	

	
	handleInputChanged(event, name) {
		const target = event.target;
		var value = target.type === 'checkbox' ? target.checked : target.value;
		
		switch(name) {
			case 'confirmNewPassword':
				this.setState({
					[name]: value,
				});
				break;
				
			default:
				let formData = this.state.formData;
				switch(name) {
					case 'accessDelete':
					case 'accessSetTarget':
						value = value ? 'Y': 'N';
						break;
					
					case 'allCustomerAccessFlag':
						if(value)
							this.setState({selectedUserCustomers: []});
						break;
					
					case 'createColorFlag':
						formData['performReadsFlag'] = value;
						break;
					
					case 'performReadsFlag':
						if(!value) {
							formData['instrumentsDisabledFlag'] = false;
						}
						break;
					
					case 'userTypeId':
						// console.log("userTypeId: ", value);
						this.filterEmailList(value);
						this.onUserRoleSelected(value);
						break;
				}
				
				formData[name] = value;
				
				this.setState({
				  formData: formData,
				});
				break;
		}
		
	}
	
	onUserProgramsViewAccess(event, name) {
		
		let formData = this.state.formData;
		let chkViewAccess = this.state.chkViewAccess;
		let chkEditAccess = this.state.chkEditAccess;
		
		chkViewAccess[name] = event.target.checked;
		
		const value = chkEditAccess[name] ? 'E' : (chkViewAccess[name] ? 'V': 'N');
			
		formData[name] = value;
		this.setState({
			formData: formData,
			chkViewAccess: chkViewAccess
		});
	}
	
	onUserProgramsEditAccess(event, name) {
		let formData = this.state.formData;
		let chkViewAccess = this.state.chkViewAccess;
		let chkEditAccess = this.state.chkEditAccess;
		
		chkEditAccess[name] = event.target.checked;
		
		const value = chkEditAccess[name] ? 'E' : (chkViewAccess[name] ? 'V': 'N');
		formData[name] = value;
		this.setState({
			formData: formData,
			chkEditAccess: chkEditAccess
		});
	}
	
	onUserReportsAccess(event, name) {
		let selectedUserReports = this.state.selectedUserReports;
		
		if(event.target.checked)
			selectedUserReports[name] = true;
		else
			delete selectedUserReports[name];
		
		this.setState({
			selectedUserReports: selectedUserReports,
		});
	}
	
	onUserCustomersAccess(event, name) {
		const selectedUserCustomers = event;
		this.setState({
			selectedUserCustomers: selectedUserCustomers,
		});
	}

	onUserEmailsAccess(event, name) {
		const userEmailList = event;
		this.setState({
			userEmailList: userEmailList,
			bEmailAccessChange:  true,
		});
	}
	
	onCheckAllAccess(event, name) {
		const checked = event.target.checked;
		const accessVariables = Object.keys(this.state.chkViewAccess);
		let chkViewAccess = this.state.chkViewAccess;
		let chkEditAccess = this.state.chkEditAccess;
		let formData = this.state.formData;
		let selectedUserReports = this.state.selectedUserReports;
		
		switch(name) {
			case 'chkAllViewAccess':
				accessVariables.forEach(varName => {
					chkViewAccess[varName] = checked;
					formData[varName] = chkEditAccess[varName] ? 'E' : (checked ? 'V': 'N');
				});
				break;
			
			case 'chkAllEditAccess':
				accessVariables.forEach(varName => {
					chkEditAccess[varName] = checked;
					formData[varName] = checked ? 'E' : (chkViewAccess[varName] ? 'V': 'N');
				});
				break;
			
			
			case 'chkAllRightsAccess':
				formData.accountingFlag = checked; // Accounting functions
				formData.confirmSOFlag = checked; // Confirm Sales Order
				formData.editCompanyFlag = checked; // Edit Company Information
				formData.formulaPricingBreakdownFlag = checked; // View Formula Pricing breakdown
				formData.formulaPricingApprovalFlag = checked; // Approve Formula Price
				formData.userMaintenanceFlag = checked;	// User Maintenance 
				formData.userMaintenanceRightsFlag = checked;	// User Maintenance Rights
				formData.userMaintenanceCustomerFlag = checked;// User Maintenance Customer
				formData.userMaintenanceAccessFlag = checked;// User Maintenance Access
				formData.userMaintenanceReportsFlag = checked;// User Maintenance Reports
				formData.formulaVisibleToCustomerFlag = checked;// Formula Visible for Customer flag
				formData.shipmentOutlierAccessFlag = checked;// shipment Outlier visible for Customer flag
				
				break;
			
			case 'chkAllColorConnexionAccess':
				formData.jobInterfaceFlag = checked;
				formData.adminFlag = checked;
				formData.createColorFlag = checked;
				formData.accessSetTarget = checked ? 'Y' : 'N';
				formData.performReadsFlag = checked;
				formData.maintainJobFlag = checked;
				formData.accessDelete = checked ? 'Y' : 'N';
				formData.deactivateFormulaFlag = checked;
				formData.notesFlag = checked;
				formData.instrumentsDisabledFlag = checked;
				formData.inkQualificationQCFlag = checked;
				
				break;
			
			case 'chkAllReportsAccess':
				this.state.reports.forEach(report => {
					if(checked)
						selectedUserReports[report.id] = true;
					else 
						delete selectedUserReports[report.id];
				});
				
				break;
		}

		this.setState({
			[name]: checked,
			formData: formData,
			selectedUserReports: selectedUserReports,
		});
	}

	//////////////////////////////// YEARLY BUDGETS FUNCTIONALITY /////////////////////////////////
	onAddBudget(event) {
		event.preventDefault();
		
		this.setState({
			editBudgetRow: null,
			selectedBudgetRowIndex: null,
			bShowBudgetForm: true,
		});
	}

	budgetRowSelected(rowIndex) {
		this.setState({
			selectedBudgetRowIndex: rowIndex,
		});
	}

	onRemoveBudget(event) {
		event.preventDefault();
		
		const selectedBudgetRowIndex = this.state.selectedBudgetRowIndex;
		if(selectedBudgetRowIndex != null) {
			var yearlyBudgets = this.state.yearlyBudgets;
			var deletedYearlyBudgetIds = this.state.deletedYearlyBudgetIds;
		
			const yearlyBudget = this.state.yearlyBudgets[selectedBudgetRowIndex];
			// Remove it there and then
			/*
			let ans = window.confirm("Are you sure?");
			if(!ans) return false;
			
			postAPI("/budgets/" + yearlyBudget.id, yearlyBudget).then(resyearlyBudget => {
				yearlyBudgets.splice(selectedBudgetRowIndex, 1);
				this.setState({
					yearlyBudgets: yearlyBudgets,
				});
			});*/
			
			if(yearlyBudget.id > 0)
				deletedYearlyBudgetIds.push(yearlyBudget);
			
			// yearlyBudgets.pop();
			yearlyBudgets.splice(selectedBudgetRowIndex, 1);
			this.setState({
				yearlyBudgets: yearlyBudgets,
				deletedYearlyBudgetIds: deletedYearlyBudgetIds,
			});
			
		}
	}

	editBudget(editRow) {
		this.setState({
			editBudgetRow: editRow,
			bShowBudgetForm: true,
		});
	}

	saveYearlyBudget(yearlyBudget) {

		var yearlyBudgets = this.state.yearlyBudgets;
		yearlyBudget.bHasChanged = true;
		if(this.state.selectedBudgetRowIndex != null)
		{
			//////// THIS WAS EDIT MODE
			yearlyBudgets[this.state.selectedBudgetRowIndex] = yearlyBudget;	
		}
		else
		{
			///////// THIS WAS INSERT MODE
			yearlyBudgets.push(yearlyBudget);
		}
		this.setState({
			yearlyBudgets: yearlyBudgets,
			bShowBudgetForm: false,
		});
	}
	
	/////////////////////// MONTHLY EXPENDITURES /////////////////////////////////
	onAddExpenditure(event) {
		event.preventDefault();
		
		this.setState({
			editExpenditureRow: null,
			selectedExpenditureRowIndex: null,
			bShowExpenditureForm: true,
		});
	}

	expenditureRowSelected(rowIndex) {
		this.setState({
			selectedExpenditureRowIndex: rowIndex,
		});
	}

	onRemoveExpenditure(event) {
		event.preventDefault();
		
		const selectedExpenditureRowIndex = this.state.selectedExpenditureRowIndex;
		if(selectedExpenditureRowIndex != null) {
			var monthlyExpenditures = this.state.monthlyExpenditures;
			var deletedMonthlyExpenditureIds = this.state.deletedMonthlyExpenditureIds;
		
			const monthlyExpenditure = this.state.monthlyExpenditures[selectedExpenditureRowIndex];
			// console.log("onRemoveExpenditure() - monthlyExpenditure: ", monthlyExpenditure);
			
			const month = monthlyExpenditure['month'];
			const year = monthlyExpenditure['year'];
			/*
			// Remove it there and then
			let ans = window.confirm("Are you sure?");
			if(!ans) return false;
			
			postAPI("/expenditures/" + this.state.formData.id + "/deleteUserExpenditures?month=" + month + "&year=" + year).then(resmonthlyExpenditure => {
				monthlyExpenditures.splice(selectedExpenditureRowIndex, 1);
				this.setState({
					monthlyExpenditures: monthlyExpenditures,
				});
			});*/
			
			if(monthlyExpenditure.id != undefined)
				deletedMonthlyExpenditureIds.push(monthlyExpenditure);
			
			// monthlyExpenditures.pop();
			monthlyExpenditures.splice(selectedExpenditureRowIndex, 1);
			this.setState({
				monthlyExpenditures: monthlyExpenditures,
				deletedMonthlyExpenditureIds: deletedMonthlyExpenditureIds,
			});
			
		}
	}

	editExpenditure(editRow) {
		this.setState({
			editExpenditureRow: editRow,
			bShowExpenditureForm: true,
		});
	}

	saveMonthlyExpenditure(monthlyExpenditure) {
		this.refreshMonthlyExpenditures(this.state.formData.id);
	}
	
	refreshMonthlyExpenditures(userId) {
		getAPI("expenditures/" + userId + "/list").then(resMonthlyExpenditures => {
			var monthlyExpenditures = resMonthlyExpenditures['data'].map(obj => {
				obj.monthName = months[obj['month']];
				return obj;
			});
			// console.log("refreshMonthlyExpenditures: ", monthlyExpenditures);
			this.setState({
				monthlyExpenditures: monthlyExpenditures,
				bShowExpenditureForm: false,
			});
		});
	}
	
	
	//////////////////////////////// PROSPECT CALLS FUNCTIONALITY /////////////////////////////////
	onAddProspectCall(event) {
		event.preventDefault();
		
		this.setState({
			editProspectCallRow: null,
			selectedProspectCallRowIndex: null,
			bShowProspectCallForm: true,
		});
	}

	prospectCallRowSelected(rowIndex) {
		this.setState({
			selectedProspectCallRowIndex: rowIndex,
		});
	}

	onRemoveProspectCall(event) {
		event.preventDefault();
		
		const selectedProspectCallRowIndex = this.state.selectedProspectCallRowIndex;
		if(selectedProspectCallRowIndex != null) {
			var monthlyProspectCalls = this.state.monthlyProspectCalls;
			var deletedMonthlyProspectCallIds = this.state.deletedMonthlyProspectCallIds;
		
			const prospectCall = this.state.monthlyProspectCalls[selectedProspectCallRowIndex];
			/*
			// Remove it there and then
			let ans = window.confirm("Are you sure?");
			if(!ans) return false;
			
			
			
			postAPI("/prospectcalls/" + prospectCall.id, prospectCall).then(resProspectCall => {
				monthlyProspectCalls.splice(selectedProspectCallRowIndex, 1);
				this.setState({
					monthlyProspectCalls: monthlyProspectCalls,
				});
			});*/
			
			
			if(prospectCall.id > 0)
				deletedMonthlyProspectCallIds.push(prospectCall);
			
			// monthlyProspectCalls.pop();
			monthlyProspectCalls.splice(selectedProspectCallRowIndex, 1);
			this.setState({
				monthlyProspectCalls: monthlyProspectCalls,
				deletedMonthlyProspectCallIds: deletedMonthlyProspectCallIds,
			});
			
		}
	}

	editProspectCall(editRow) {
		this.setState({
			editProspectCallRow: editRow,
			bShowProspectCallForm: true,
		});
	}

	saveMonthlyProspectCall(prospectCall) {
		var monthlyProspectCalls = this.state.monthlyProspectCalls;
		prospectCall.bHasChanged = true;
		if(this.state.selectedProspectCallRowIndex != null)
		{
			//////// THIS WAS EDIT MODE
			monthlyProspectCalls[this.state.selectedProspectCallRowIndex] = prospectCall;	
		}
		else
		{
			///////// THIS WAS INSERT MODE
			monthlyProspectCalls.push(prospectCall);
		}
		this.setState({
			monthlyProspectCalls: monthlyProspectCalls,
			bShowProspectCallForm: false,
		});
	}

	refreshMonthlyProspectCalls(userId) {
		getAPI("prospectcalls/" + userId + "/list").then(resMonthlyProspectCalls => {
			var monthlyProspectCalls = resMonthlyProspectCalls['data'].map(obj => {
				obj.monthName = months[obj['month']];
				return obj;
			});
			// console.log("refreshMonthlyProspectCalls: ", monthlyProspectCalls);
			this.setState({
				monthlyProspectCalls: monthlyProspectCalls,
				bShowProspectCallForm: false,
			});
		});

	}

	onSave() {
		let formData = this.state.formData;
		// PERFORM VALIDATION CHECKS HERE
		let validation = validateForm(formData, validationFields);
		
		// Passwords must match; this cannot be specified in the validationFields since 
		//	confirmNewPassword does not belong to formData
		// if(!this.props.editUserId) {
			if(formData.password != this.state.confirmNewPassword) {
				validation.errors['confirmNewPassword'] = "Passwords do not match";
				validation.result = false;
			}
		// }
		if(this.props.editUserId)
			if(formData.password)
				formData.passwordChanged = true;
			
		// console.log("formData in onSave(): ", formData);
		
		// console.log("validation in UserForm: ", validation);
		// IF FORM IS NOT VALIDATED, THEN SET STATE ACCORDINGLY
		if(!validation.result)
		{
			showFormValidationAlert(validation.errors);
			this.setState({
				errors: validation.errors
			});
		}
		else
		{
			let bAllCanEditChecked = formData.accessCustomersCode == 'E' && formData.accessComponentsCode == 'E' && formData.accessFormulasCode == 'E' && formData.accessFormulaPricingCode == 'E' && formData.accessInventoryCode == 'E' && formData.accessPOCode == 'E' && formData.accessOrdersCode == 'E' && formData.accessVendorsCode == 'E' && formData.accessShippersCode == 'E';
			// console.log("formData: ", formData);
			// console.log("chkEditAccess: ", this.state.chkEditAccess);
			// console.log("chkViewAccess: ", this.state.chkViewAccess);
			
			if(bAllCanEditChecked) {
				let proceed = window.confirm("All 'Can Edit' checkboxes under 'Program Access' seem to be checked. Are you sure this is correct?");
				// console.log("proceed: ", proceed);
				if(!proceed)
					return false;
			}
			
			postAPI("users", formData).then(resUser => {
			if(resUser['success']) {
				const userId = resUser['data'][0]['id'];
				// Adding new customers
				var addCustomerPromises = [];
				for(const i in this.state.selectedUserCustomers) {
					const selectedUserCustomer = this.state.selectedUserCustomers[i];
					const customerId = selectedUserCustomer.value;
					const customerRow = this.state.userCustomers.filter(obj => obj.customerId == customerId)[0];
					if(!customerRow)
					{
						const userCustomer = {"customerId":customerId,
							"model":"com.phototype.myinkiq.model.vos.dtos::UserCustomersDto",
							"id":0,
							"name":null,
							"userId":userId
						};
						// console.log(userCustomer)
						addCustomerPromises.push(postAPI("users/" + userId + "/customers", userCustomer).then(resUserCustomer => {	
						}));
					}
					
				}
				
				// Deleting any customers
				var deleteCustomerPromises = [];
				for(const i in this.state.userCustomers) {
					const userCustomer = this.state.userCustomers[i];
					const customerId = userCustomer.customerId;
					const customerRow = this.state.selectedUserCustomers.filter(obj => obj.value == customerId)[0];
					if(!customerRow)
					{
						deleteCustomerPromises.push(postAPI("users/" + userId + "/customers/" + userCustomer.id, userCustomer).then(resUserCustomer => {	
						}));
					}
					
				}
                
				if (this.state.bEmailAccessChange){
				var addEmailPromises = [];
                var emailId = [];
				for(const i in this.state.userEmailList) {
					const selectedUserEmail = this.state.userEmailList[i];
					// console.log("selectedUserEmail:",selectedUserEmail.value);
					 
					emailId.push(selectedUserEmail.value);
				    // console.log("emailId:",emailId);
				}
				// console.log("emailId:",emailId[0]);
				addEmailPromises.push(getAPI("users/" + userId + "/saveemails?emailIds="+emailId+"&bDelete=1" ).then(userEmailList => {
                }));
			}

				
				// Adding user reports
				var addReportPromises = [];
				for(const reportId in this.state.selectedUserReports) {
					const reportRow = this.state.userReports.filter(obj => obj.reportId == reportId)[0];
					if(!reportRow)
					{
						const userReport = 
						{
							"model":"com.phototype.myinkiq.model.vos.dtos::UserReportsDto",
							"id":0,
							"reportId":reportId,
							"name":null,
							"userId":userId
						};
						addReportPromises.push(postAPI("users/" + userId + "/reports", userReport).then(resUserReport => {	
						}));
					}
				}
				
				// Deleting user Reports
				var deleteReportPromises = [];
				for(const i in this.state.userReports) {
					const userReport = this.state.userReports[i];
					const reportId = userReport.reportId;
					const reportRow = this.state.selectedUserReports[reportId];
					if(!reportRow) {
						deleteReportPromises.push(postAPI("users/" + userId + "/reports/" + userReport.id, userReport).then(resUserReport => {	
						}));
					}
				}
				
				// Adding Yearly Budget
				var addYearlyBudgetPromises = [];
				for(const i in this.state.yearlyBudgets) {
					var yearlyBudget = this.state.yearlyBudgets[i];
					yearlyBudget['userId'] = userId;
					if(yearlyBudget.bHasChanged != undefined && yearlyBudget.bHasChanged)
					{
						delete(yearlyBudget.bHasChanged);
						addYearlyBudgetPromises.push(postAPI("budgets/", yearlyBudget).then(resyearlyBudget => {
						}));
					}
				}
				
				// Deleting Yearly Budget
				var removeBudgetPromises = [];
				for(const i in this.state.deletedYearlyBudgetIds) {
					const yearlyBudget = this.state.deletedYearlyBudgetIds[i];
					removeBudgetPromises.push(postAPI("/budgets/" + yearlyBudget.id, yearlyBudget).then(resyearlyBudget => {
					}));
				}
				
				// Adding Monthly Expenditure
				var addMonthlyExpenditurePromises = [];
				for(const i in this.state.monthlyExpenditures) {
					var monthlyExpenditure = this.state.monthlyExpenditures[i];
					monthlyExpenditure['userId'] = userId;
					if(monthlyExpenditure.bHasChanged != undefined && monthlyExpenditure.bHasChanged)
					{
						delete(monthlyExpenditure.bHasChanged);
						addMonthlyExpenditurePromises.push(postAPI("expenditures/", monthlyExpenditure).then(resmonthlyExpenditure => {
						}));
					}
				}

				// Deleting Monthly Expenditure
				var removeExpenditurePromises = [];
				// console.log("this.state.deletedMonthlyExpenditureIds: ", this.state.deletedMonthlyExpenditureIds);
				
				for(const i in this.state.deletedMonthlyExpenditureIds) {
					const monthlyExpenditure = this.state.deletedMonthlyExpenditureIds[i];
					// removeExpenditurePromises.push(postAPI("/expenditures/" + monthlyExpenditure.id, monthlyExpenditure).then(resmonthlyExpenditure => {}));
					
					const month = monthlyExpenditure['month'];
					const year = monthlyExpenditure['year'];
					
					removeExpenditurePromises.push(postAPI("/expenditures/" + userId + "/deleteUserExpenditures?month=" + month + "&year=" + year).then(resmonthlyExpenditure => {}));
				}
				
				// Adding Monthly ProspectCall
				var addMonthlyProspectCallPromises = [];
				for(const i in this.state.monthlyProspectCalls) {
					var monthlyProspectCall = this.state.monthlyProspectCalls[i];
					monthlyProspectCall['userId'] = userId;
					if(monthlyProspectCall.bHasChanged != undefined && monthlyProspectCall.bHasChanged)
					{
						delete(monthlyProspectCall.bHasChanged);
						addMonthlyProspectCallPromises.push(postAPI("/prospectcalls/", monthlyProspectCall).then(resmonthlyProspectCall => {
						}));
					}
				}

				// Deleting Monthly ProspectCall
				var removeProspectCallPromises = [];
				
				for(const i in this.state.deletedMonthlyProspectCallIds) {
					const prospectCall = this.state.deletedMonthlyProspectCallIds[i];
					removeProspectCallPromises.push(postAPI("/prospectcalls/" + prospectCall.id, prospectCall).then(resProspectCall => {
					}));
				}
				
				

				Promise.all(addCustomerPromises)
				.then(() => {
					Promise.all(deleteCustomerPromises)
					.then(() => {
						Promise.all(addReportPromises)
						.then(() => {
							Promise.all(deleteReportPromises)
							.then(() => {
								Promise.all(addYearlyBudgetPromises)
								.then(() => {
									Promise.all(removeBudgetPromises)
									.then(() => {										
										Promise.all(addMonthlyExpenditurePromises)
										.then(() => {
											Promise.all(removeExpenditurePromises)
											.then(() => {
												Promise.all(addMonthlyProspectCallPromises)
												.then(() => {
													Promise.all(removeProspectCallPromises)
													.then(() => {
														// alert(<Translate value="components.labels.componentSaved" />);
														alert("User info Saved successfully.");
														this.props.onSave();
													});
												});
											});
										});
									});
								});
							});
						});
					});
				});
			}
			else
			{
				// alert(resUser['error']['error']);
				var errorStatus = resUser['error']['status'];
				var errorMsg = resUser['error']['error'];
				// console.log("errorStatus: ", errorStatus, ", errorMsg: ", errorMsg);
				if(errorStatus == 401 && errorMsg.indexOf("already") >= 0) {
					alert("Username is already used by another user.");
				} else {
					alert("Error: ", resUser['error']['error']);
				}
			}
			
		});
		}
	}

	onDelete() {
		postAPI("users/" + this.state.formData.id, this.state.formData).then(resUser => {

			// alert(<Translate value="components.labels.poDeleted" />);
			alert("User has been deleted successfully");
			this.props.onSave();
		});
	}
	
	onInactivate() {
		let formData = this.state.formData;
		formData.retired = 1;
		postAPI("users", formData).then(resUser => {
			// console.log("onInactivate() - ", resUser);
			if(resUser.success) {
				alert("User has been inactivated successfully");
				this.props.onSave();
			} else {
				alert("Error Inactivating - " + resUser.error.message);
			}
		});

			
	}

	render() {
		const loggedInUser = getLoggedInUser();
		let emailList = this.state.emailList
		emailList = emailList.map(emailLists => ({value: emailLists.id, label: emailLists.name}))
		const userTypes = mapArrayToObject(this.state.userTypesList, 'id', 'name');
		const customersList = this.props.activeCustomers.map((obj, key) => ({value: obj.id, label: obj.name}));
		const months = {
			"jan": "January",
			"feb": "February",
			"mar": "March",
			"apr": "April",
			"may": "May",
			"jun": "June",
			"jul": "July",
			"aug": "August",
			"sep": "September",
			"oct": "October",
			"nov": "November",
			"dec": "December",
		};
		
		const editBudgetRow = this.state.editBudgetRow;
		const yearlyBudgetForm = this.state.bShowBudgetForm ? (<YearlyBudgetForm
			visible={this.state.bShowBudgetForm}
			onClose={() => this.setState({bShowBudgetForm: false})}
			onSave={this.saveYearlyBudget}
			editBudgetRow={editBudgetRow}
			userId={this.state.formData.id}
		/>)
		: '';
		
		const editExpenditureRow = this.state.editExpenditureRow;
		const monthlyExpenditureForm = this.state.bShowExpenditureForm ? (<MonthlyExpenditureForm
			visible={this.state.bShowExpenditureForm}
			onClose={() => this.setState({bShowExpenditureForm: false})}
			onSave={this.saveMonthlyExpenditure}
			editExpenditureRow={editExpenditureRow}
			userId={this.state.formData.id}
		/>)
		: '';
		
		const editProspectCallRow = this.state.editProspectCallRow;
		const monthlyProspectCallForm = this.state.bShowProspectCallForm ? (<MonthlyProspectCallForm
			visible={this.state.bShowProspectCallForm}
			onClose={() => this.setState({bShowProspectCallForm: false})}
			onSave={this.saveMonthlyProspectCall}
			editProspectCallRow={editProspectCallRow}
			userId={this.state.formData.id}
		/>)
		: '';

		const lastLoginDate = this.state.formData.lastLoginDate ? convertTimestampToDate(this.state.formData.lastLoginDate, true) : '';
		const reportAccessContent = this.state.reportsList.map((reports, i) => 
			<Panel>
				<Panel.Heading>
					<Panel.Title toggle>{reports.category}</Panel.Title>
				</Panel.Heading>
				<Panel.Collapse>
					<Panel.Body>
					{reports.reports.map(report => 
						<InputText type='CheckBox' label={report.name} id='agingReport' name={report.id} action={this.onUserReportsAccess} value={this.state.selectedUserReports[report.id] != undefined} />
					)}
					</Panel.Body>
				</Panel.Collapse>
			</Panel>
		);
		
		const bCustomerLoggedIn = loggedInUser.userTypeId == constants.customer_type_id;
		const bEditProfile = this.props.bEditProfile;
		const bCanChangePassword = !this.props.editUserId
		 || loggedInUser.userTypeId == constants.vip_type_id
		 || (this.props.editUserId && loggedInUser.id == this.state.formData.id)
		
		var content = <Form className = 'form-page' horizontal>
			<Row>
				<Col md={4}>
					<h4><Translate value='components.settings.userInfo' /></h4>
					{!bEditProfile &&
					<DataDropdown required 
						label={<Translate value='components.settings.userType' />} data={userTypes} 
						name='userTypeId' value={this.state.formData.userTypeId} 
						action={this.handleInputChanged} 
						errMsg={this.state.errors.userTypeId}
						disabled={bCustomerLoggedIn}
					/>
					}
					<InputText required label={<Translate value='components.settings.firstName' />} 
						name='firstName' value={this.state.formData.firstName} 
						action={this.handleInputChanged}  
						errMsg={this.state.errors.firstName}
					/>
					<InputText required label={<Translate value='components.settings.lastName' />} 
						name='lastName' value={this.state.formData.lastName} 
						action={this.handleInputChanged}  
						errMsg={this.state.errors.lastName}
					/>
					<InputText required label={<Translate value='components.settings.email' />} 
						name='emailAddress' value={this.state.formData.emailAddress} 
						action={this.handleInputChanged}  
						errMsg={this.state.errors.emailAddress}
					/>
					<InputText required 
						label={<Translate value='components.settings.displayName' />} 
						name='displayName' value={this.state.formData.displayName} 
						action={this.handleInputChanged}  
						errMsg={this.state.errors.displayName}
					/>
					<InputText required 
						label={<Translate value='components.settings.userName' />} 
						name='userName' value={this.state.formData.userName} 
						action={this.handleInputChanged}  
						errMsg={this.state.errors.userName}
					/>
					{!bEditProfile &&
					<span>
					<InputText type='CheckBox' 
						label={<Translate value='components.settings.inactive' />} 
						name='retired' value={this.state.formData.retired} 
						action={this.handleInputChanged}  
					/>
					{bCanChangePassword && <InputText required 
						label={<Translate value='components.settings.newPassword' />} 
						name='password' value={this.state.formData.password} 
						action={this.handleInputChanged}  
						errMsg={this.state.errors.password}
					/>}
					{bCanChangePassword && <InputText required 
						label={<Translate value='components.settings.confirmNewPassword' />} 
						name='confirmNewPassword' value={this.state.confirmNewPassword} 
						action={this.handleInputChanged}  
						errMsg={this.state.errors.confirmNewPassword}
					/>}
					<InputText type='CheckBox' 
						label={<Translate value='components.settings.requireChange' />} 
						name='requireFlag' value={this.state.formData.requireFlag} 
						action={this.handleInputChanged} 
					/>
					</span>
					}
				</Col>
				{!bEditProfile &&
				<Col md={8}>
					<h4><Translate value='components.settings.programAccess' /></h4>
					<Row>
						<Col md={6}>
							<h5><Translate value='components.settings.canView' /></h5>
							<InputText type='CheckBox' 
								label={<Translate value='components.settings.selectAll' />}
								name='chkAllViewAccess' 
								value={this.state.chkAllViewAccess} 
								action={this.onCheckAllAccess} 
							 />
							{!bCustomerLoggedIn && 
							<InputText type='CheckBox' 
								label={<Translate value='components.settings.customers' />} 
								name='accessCustomersCode' 
								value={this.state.chkViewAccess.accessCustomersCode} 
								action={this.onUserProgramsViewAccess} 
							/>
							}
							{!bCustomerLoggedIn && 
							<InputText type='CheckBox' 
								label={<Translate value='components.settings.components' />} 
								name='accessComponentsCode' 
								value={this.state.chkViewAccess.accessComponentsCode} 
								action={this.onUserProgramsViewAccess} 
							/>
							}
							<InputText type='CheckBox' 
								label={<Translate value='components.settings.formulas' />} 
								name='accessFormulasCode' 
								value={this.state.chkViewAccess.accessFormulasCode} 
								action={this.onUserProgramsViewAccess} 
							/>
							<InputText type='CheckBox' 
								label={<Translate value='components.settings.formulaPricing' />} 
								name='accessFormulaPricingCode' 
								value={this.state.chkViewAccess.accessFormulaPricingCode} 
								action={this.onUserProgramsViewAccess} 
							/>
							{!bCustomerLoggedIn && 
							<InputText type='CheckBox' 
								label={<Translate value='components.settings.inventory' />} 
								name='accessInventoryCode' 
								value={this.state.chkViewAccess.accessInventoryCode} 
								action={this.onUserProgramsViewAccess} 
							/>
							}
							{!bCustomerLoggedIn && 
							<InputText type='CheckBox' 
								label={<Translate value='components.settings.pos' />} 
								name='accessPOCode' value={this.state.chkViewAccess.accessPOCode} 
								action={this.onUserProgramsViewAccess} 
							/>
							}
							<InputText type='CheckBox' 
								label={<Translate value='components.settings.salesOrders' />} 
								name='accessOrdersCode' 
								value={this.state.chkViewAccess.accessOrdersCode} 
								action={this.onUserProgramsViewAccess} 
							/>
							{!bCustomerLoggedIn && 
							<InputText type='CheckBox' 
								label={<Translate value='components.settings.vendors' />} 
								name='accessVendorsCode' 
								value={this.state.chkViewAccess.accessVendorsCode} 
								action={this.onUserProgramsViewAccess} 
							/>
							}
							{!bCustomerLoggedIn && 
							<InputText type='CheckBox' 
								label={<Translate value='components.settings.shippers' />} 
								name='accessShippersCode' 
								value={this.state.chkViewAccess.accessShippersCode} 
								action={this.onUserProgramsViewAccess} 
							/>
							}
						</Col>
						<Col md={6}>
							<h5><Translate value='components.settings.canEdit' /></h5>
							<InputText type='CheckBox' 
								label={<Translate value='components.settings.selectAll' />}
								name='chkAllEditAccess' 
								value={this.state.chkAllEditAccess} 
								action={this.onCheckAllAccess} 
							 />
							{!bCustomerLoggedIn && 
							<InputText type='CheckBox' 
								label={<Translate value='components.settings.customers' />} 
								name='accessCustomersCode' 
								value={this.state.chkEditAccess.accessCustomersCode} 
								action={this.onUserProgramsEditAccess} 
							/>
							}
							{!bCustomerLoggedIn && 
							<InputText type='CheckBox' 
								label={<Translate value='components.settings.components' />} 
								name='accessComponentsCode' 
								value={this.state.chkEditAccess.accessComponentsCode} 
								action={this.onUserProgramsEditAccess} 
							/>
							}
							{!bCustomerLoggedIn && 
							<InputText type='CheckBox' 
								label={<Translate value='components.settings.formulas' />} 
								name='accessFormulasCode' 
								value={this.state.chkEditAccess.accessFormulasCode} 
								action={this.onUserProgramsEditAccess} 
							/>
							}
							{!bCustomerLoggedIn && 
							<InputText type='CheckBox' 
								label={<Translate value='components.settings.formulaPricing' />} 
								name='accessFormulaPricingCode' 
								value={this.state.chkEditAccess.accessFormulaPricingCode} 
								action={this.onUserProgramsEditAccess} 
							/>
							}
							{!bCustomerLoggedIn && 
							<InputText type='CheckBox' 
								label={<Translate value='components.settings.inventory' />} 
								name='accessInventoryCode' 
								value={this.state.chkEditAccess.accessInventoryCode} 
								action={this.onUserProgramsEditAccess} 
							/>
							}
							{!bCustomerLoggedIn && 
							<InputText type='CheckBox' 
								label={<Translate value='components.settings.pos' />} 
								name='accessPOCode' value={this.state.chkEditAccess.accessPOCode} 
								action={this.onUserProgramsEditAccess} 
							/>
							}
							<InputText type='CheckBox' 
								label={<Translate value='components.settings.salesOrders' />} 
								name='accessOrdersCode' 
								value={this.state.chkEditAccess.accessOrdersCode} 
								action={this.onUserProgramsEditAccess} 
							/>
							{!bCustomerLoggedIn && 
							<InputText type='CheckBox' 
								label={<Translate value='components.settings.vendors' />} 
								name='accessVendorsCode' 
								value={this.state.chkEditAccess.accessVendorsCode} 
								action={this.onUserProgramsEditAccess} 
							/>
							}
							{!bCustomerLoggedIn && 
							<InputText type='CheckBox' 
								label={<Translate value='components.settings.shippers' />} 
								name='accessShippersCode' 
								value={this.state.chkEditAccess.accessShippersCode} 
								action={this.onUserProgramsEditAccess} 
							/>
							}
						</Col>
					</Row>
				</Col>
				}
			</Row>
			{!bEditProfile &&
			<Row>
				<Col md={4}>
					<h4><Translate value='components.settings.sessionInfo' /></h4>
					<InputText type='Label' 
						label={<Translate value='components.settings.lastLogin' />} 
						value={lastLoginDate} />
					<InputText type='Label' 
						label={<Translate value='components.settings.loginCount' />} 
						value={this.state.userLoginCount} />
					<InputText type='CheckBox' 
						label={<Translate value='components.settings.spanish' />} 
						name={'spanishFlag'} 
						value={this.state.formData.spanishFlag} action={this.handleInputChanged} />
					
					{!bCustomerLoggedIn && 
					<span>
						<h4><Translate value='components.settings.customerAccess' /></h4>
						{this.state.formData.userTypeId != constants.customer_type_id && 
						<InputText type='CheckBox' 
							label={<Translate value='components.settings.allCustomers' />}
							name='allCustomerAccessFlag' 
							value={this.state.formData.allCustomerAccessFlag} 
							action={this.handleInputChanged} 
						 />
						 }
						<InputText type='MultiSelect' 
						label={<Translate value='components.settings.canAccessCustomers' />} 
						value={this.state.selectedUserCustomers} data={customersList} 
						action={this.onUserCustomersAccess} 
						disabled={this.state.formData.allCustomerAccessFlag}
						/>
                        
						{this.state.formData.userTypeId != constants.customer_type_id && 
                        <InputText type='MultiSelect' 
						label={<Translate value='components.settings.emailAccess' />} 
						value={this.state.userEmailList}
						data={emailList} 
						action={this.onUserEmailsAccess}
						/>
	                     }
					</span>
					}
					{(this.isSalesPerson(this.state.formData.userTypeId) || this.isLabTech(this.state.formData.userTypeId)) && 
					<span>
						<DataGrid 
							columns={this.state.yearlyBudgetColumns} 
							fields={this.state.yearlyBudgetFields}
							formatColumns={this.state.yearlyBudgetFormatColumns} 
							onDoubleClick={this.editBudget} 
							onSelect={this.budgetRowSelected} 
							data={this.state.yearlyBudgets} 
						/>
						<Button id={"btnAdd"} onClick={this.onAddBudget} 
							disabled={loggedInUser.accessComponentsCode == 'V'}>
							<Translate value="components.buttons.add" />
						</Button>&nbsp;
						<Button id={"btnRemove"} onClick={this.onRemoveBudget} 
							disabled={loggedInUser.accessComponentsCode == 'V'} >
							<Translate value="components.buttons.remove" />
						</Button>
					</span>
					}
					{yearlyBudgetForm}
					<hr />
					{(this.isSalesPerson(this.state.formData.userTypeId) || this.isLabTech(this.state.formData.userTypeId))  && 
					<span>
						<DataGrid 
							columns={this.state.monthlyExpenditureColumns} 
							fields={this.state.monthlyExpenditureFields}
							formatColumns={this.state.monthlyExpenditureFormatColumns} 
							onDoubleClick={this.editExpenditure} 
							onSelect={this.expenditureRowSelected} 
							data={this.state.monthlyExpenditures} 
						/>
						<Button id={"btnAdd"} onClick={this.onAddExpenditure} 
							disabled={loggedInUser.accessComponentsCode == 'V'}>
							<Translate value="components.buttons.add" />
						</Button>&nbsp;
						<Button id={"btnRemove"} onClick={this.onRemoveExpenditure} 
							disabled={loggedInUser.accessComponentsCode == 'V'} >
							<Translate value="components.buttons.remove" />
						</Button>
					</span>
					}
					{monthlyExpenditureForm}
					<hr />
					{(this.isSalesPerson(this.state.formData.userTypeId) || this.isLabTech(this.state.formData.userTypeId))  && 
					<span>
						<DataGrid 
							columns={this.state.monthlyProspectCallColumns} 
							fields={this.state.monthlyProspectCallFields}
							formatColumns={this.state.monthlyProspectCallFormatColumns} 
							onDoubleClick={this.editProspectCall} 
							onSelect={this.prospectCallRowSelected} 
							data={this.state.monthlyProspectCalls} 
						/>
						<Button id={"btnAdd"} onClick={this.onAddProspectCall} 
							disabled={loggedInUser.accessComponentsCode == 'V'}>
							<Translate value="components.buttons.add" />
						</Button>&nbsp;
						<Button id={"btnRemove"} onClick={this.onRemoveProspectCall} 
							disabled={loggedInUser.accessComponentsCode == 'V'} >
							<Translate value="components.buttons.remove" />
						</Button>
					</span>
					}
					{monthlyProspectCallForm}

				</Col>
				<Col md={4}>
					{!bCustomerLoggedIn && 
					<span>
						<h4><Translate value='components.settings.reportAccess' /></h4>
						<InputText type='CheckBox' 
							label={<Translate value='components.settings.selectAll' />}
							name='chkAllReportsAccess' 
							value={this.state.chkAllReportsAccess} 
							action={this.onCheckAllAccess} 
						 />
						{reportAccessContent}
					</span>
					}
				</Col>
				<Col md={4}>
					{!bCustomerLoggedIn && 
					<span>
						<h4><Translate value='components.settings.userRights' /></h4>
						<InputText type='CheckBox' 
							label={<Translate value='components.settings.selectAll' />}
							name='chkAllRightsAccess' 
							value={this.state.chkAllRightsAccess} 
							action={this.onCheckAllAccess} 
						 />
						<InputText
							type='CheckBox'
							label={<Translate value='components.settings.accountingFunctions' />}
							name={'accountingFlag'}
							value={this.state.formData.accountingFlag}
							action={this.handleInputChanged}
						/>
						<InputText
							type='CheckBox'
							label={<Translate value='components.settings.confirmSalesOrder' />}
							name={'confirmSOFlag'}
							value={this.state.formData.confirmSOFlag}
							action={this.handleInputChanged}
						/>
						<InputText
							type='CheckBox'
							label={<Translate value='components.settings.userMaintanence' />}
							tooltip={<Translate value='components.tooltips.userMaint' />}
							name={'userMaintenanceFlag'}
							value={this.state.formData.userMaintenanceFlag}
							action={this.handleInputChanged}
						/>
						<InputText
							type='CheckBox'
							label={<Translate value='components.settings.userMaintanenceCustomer' />}
							tooltip={<Translate value='components.tooltips.userMaintCustomer' />}
							name={'userMaintenanceCustomerFlag'}
							value={this.state.formData.userMaintenanceCustomerFlag}
							action={this.handleInputChanged}
						/>
						<InputText
							type='CheckBox'
							label={<Translate value='components.settings.userMaintanenceAccess' />}
							tooltip={<Translate value='components.tooltips.userMaintAccess' />}
							name={'userMaintenanceAccessFlag'}
							value={this.state.formData.userMaintenanceAccessFlag}
							action={this.handleInputChanged}
						/>
						<InputText
							type='CheckBox'
							label={<Translate value='components.settings.editCompanyInformation' />}
							name={'editCompanyFlag'}
							value={this.state.formData.editCompanyFlag}
							action={this.handleInputChanged}
						/>
						<InputText
							type='CheckBox'
							label={<Translate value='components.settings.formulaPriceBreakdown' />}
							name={'formulaPricingBreakdownFlag'}
							value={this.state.formData.formulaPricingBreakdownFlag}
							action={this.handleInputChanged}
						/>
						<InputText
							type='CheckBox'
							label={<Translate value='components.settings.approveFormulaPrice' />}
							name={'formulaPricingApprovalFlag'}
							value={this.state.formData.formulaPricingApprovalFlag}
							action={this.handleInputChanged}
						/>
						<InputText
							type='CheckBox'
							label={<Translate value='components.settings.userMaintanenceRights' />}
							tooltip={<Translate value='components.tooltips.userMaintRights' />}
							name={'userMaintenanceRightsFlag'}
							value={this.state.formData.userMaintenanceRightsFlag}
							action={this.handleInputChanged}
						/>
						<InputText
							type='CheckBox'
							label={<Translate value='components.settings.userMaintanenceReports' />}
							tooltip={<Translate value='components.tooltips.userMaintReports' />}
							name={'userMaintenanceReportsFlag'}
							value={this.state.formData.userMaintenanceReportsFlag}
							action={this.handleInputChanged}
						/>
						<InputText
							type='CheckBox'
							label={<Translate value='components.settings.visibleToCustomer' />}
							tooltip={<Translate value='components.tooltips.visibleToCustomerFlag' />}
							name={'formulaVisibleToCustomerFlag'}
							value={this.state.formData.formulaVisibleToCustomerFlag}
							action={this.handleInputChanged}
						/>
						<InputText
							type='CheckBox'
							label={<Translate value='components.settings.shipmentOutlierAccess' />}
							tooltip={<Translate value='components.tooltips.shipmentOutlierAccessFlag' />}
							name={'shipmentOutlierAccessFlag'}
							value={this.state.formData.shipmentOutlierAccessFlag}
							action={this.handleInputChanged}
						/>
					</span>
					}

					<h4><Translate value='components.settings.colorConnexionAccess' /></h4>
					
					<InputText type='CheckBox' 
						label={<Translate value='components.settings.selectAll' />}
						name='chkAllColorConnexionAccess' 
						value={this.state.chkAllColorConnexionAccess} 
						action={this.onCheckAllAccess} 
					 />
					<InputText type='CheckBox' 
						label={<Translate value='components.settings.colorConnexion' />} 
						name={'jobInterfaceFlag'} value={this.state.formData.jobInterfaceFlag} 
						action={this.handleInputChanged} />
					
					<InputText type='CheckBox' 
						label={<Translate value='components.settings.admin' />}
						tooltip={<Translate value='components.tooltips.admin' />}
						name={'adminFlag'} value={this.state.formData.adminFlag} 
						action={this.handleInputChanged} />

					{this.state.formData.userTypeId == constants.customer_type_id &&
					this.state.formData.jobInterfaceFlag &&  
					<InputText type='CheckBox' 
						label={<Translate value='components.settings.allowCreateColor' />}
						tooltip={<Translate value='components.tooltips.createColor' />}
						name={'createColorFlag'} value={this.state.formData.createColorFlag} 
						action={this.handleInputChanged} />
					}
					{this.state.formData.userTypeId != constants.customer_type_id && 
					this.state.formData.jobInterfaceFlag && 
					<InputText type='CheckBox' 
						label={<Translate value='components.settings.accessSetTarget' />} 
						name={'accessSetTarget'} value={this.state.formData.accessSetTarget == 'Y'} 
						action={this.handleInputChanged} />
					}
					{this.state.formData.jobInterfaceFlag && 
					<InputText type='CheckBox' 
						label={<Translate value='components.settings.allowPerformReads' />}
						tooltip={<Translate value='components.tooltips.performReads' />}
						name={'performReadsFlag'} value={this.state.formData.performReadsFlag} 
						action={this.handleInputChanged} />
					}
					{this.state.formData.jobInterfaceFlag && this.state.formData.performReadsFlag && 
					<InputText type='CheckBox' 
						label={<Translate value='components.settings.instrumentsDisabledFlag' />}
						tooltip={<Translate value='components.tooltips.instrumentsDisabledFlag' />}
						name={'instrumentsDisabledFlag'} value={this.state.formData.instrumentsDisabledFlag} 
						action={this.handleInputChanged} 
						disabled={!this.state.formData.performReadsFlag} 
						bIndent={true} />
					}
					{this.state.formData.userTypeId == constants.customer_type_id && 
					this.state.formData.jobInterfaceFlag && 
					<InputText type='CheckBox' 
						label={<Translate value='components.settings.allowCreateEditJobs' />} 
						name={'maintainJobFlag'} value={this.state.formData.maintainJobFlag} 
						action={this.handleInputChanged} />
					}
					{this.state.formData.performReadsFlag && 
					<InputText type='CheckBox' 
						label={<Translate value='components.settings.inkQualificationQCFlag' />}
						tooltip={<Translate value='components.tooltips.inkQualificationQCFlag' />}
						name={'inkQualificationQCFlag'} value={this.state.formData.inkQualificationQCFlag} 
						action={this.handleInputChanged} 
						disabled={!this.state.formData.performReadsFlag} 
						bIndent={true} />
					}
					{//this.state.formData.userTypeId == constants.customer_type_id && 
					this.state.formData.jobInterfaceFlag && 
					<InputText type='CheckBox' 
						label={<Translate value='components.settings.allowJobColorDelete' />} 
						tooltip={<Translate value='components.tooltips.deleteFormula' />}
						name={'accessDelete'} value={this.state.formData.accessDelete == 'Y'} 
						action={this.handleInputChanged} />
					}
					
					{//this.state.formData.userTypeId == constants.customer_type_id && 
					this.state.formData.jobInterfaceFlag && 
					<InputText type='CheckBox' 
						label={<Translate value='components.settings.allowDeactivationOfFormulas' />}
						tooltip={<Translate value='components.tooltips.deactivateFormula' />}
						name={'deactivateFormulaFlag'} 
						value={this.state.formData.deactivateFormulaFlag} 
						action={this.handleInputChanged} />
					}
					{this.state.formData.userTypeId == constants.customer_type_id && 
					this.state.formData.jobInterfaceFlag && 
					<InputText type='CheckBox' 
						label={<Translate value='components.settings.allowJobNoteCreation' />} 
						name={'notesFlag'} value={this.state.formData.notesFlag} 
						action={this.handleInputChanged} />
					}
					
				</Col>
			</Row>
			}
		</Form>

		return(<Modal show={this.props.visible} bsSize="large">
			<Modal.Header>
				<Modal.Title>{this.state.title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{content}
				<DeleteConfirmation
					visible={this.state.showDeleteConfirmation}
					onConfirm={this.onDelete}
					onClose={() => this.setState({showDeleteConfirmation: false})}
				/>
				<InactivateConfirmation
					visible={this.state.showInactivateConfirmation}
					onConfirm={this.onInactivate}
					onClose={() => this.setState({showInactivateConfirmation: false})}
				/>
			</Modal.Body>
			<Modal.Footer>
				{this.props.editUserId && false ? <Button bsStyle='danger' onClick={() => this.setState({showDeleteConfirmation: true})}>
					<Translate value="components.buttons.deleteUser" />
				</Button> : ''}
				{this.props.editUserId ? <Button bsStyle='danger' onClick={() => this.setState({showInactivateConfirmation: true})}>
					<Translate value="components.buttons.inactivate" />
				</Button> : ''}
				<Button onClick={this.props.onClose}>
					<Translate value="components.buttons.close" />
				</Button>
				<Button onClick={this.onSave} bsStyle='primary'>
					<Translate value="components.buttons.saveChanges" />
				</Button>
			</Modal.Footer>
		</Modal>);
	}
}

export default UserForm;

const validationFieldsYearlyBudget = {
	year: "required,numeric",
	totalSalesGoal: "required,numeric",
	totalExpenseBudget: "required,numeric"
};

class YearlyBudgetForm extends Component
{
	constructor(props) {
		super(props);
		
		this.state = {
			title: "Add New Yearly Budget",
			errors: resetObject(validationFieldsYearlyBudget),
			formData: {
				year: null,
				totalSalesGoal: null,
				totalExpenseBudget: null,
				prospectCallsPerMonth: null,
				id:0,
				model:"com.phototype.myinkiq.model.vos.dtos::YearlyBudgetsDto",
				retired:false,
				userId:null,
			},
		};
		
		this.handleInputChanged = this.handleInputChanged.bind(this);
		this.onSave = this.onSave.bind(this);
	}
	
	componentDidMount() {
		/////////////// CHECKING FOR EDIT MODE //////////////////
		if(this.props.editBudgetRow) { // If this is not null
			let editBudgetRow = this.props.editBudgetRow;
			editBudgetRow['userId'] = this.props.userId;
			// console.log("userId: ", this.props.userId);
			this.setState({
				formData: editBudgetRow,
			});
		}
		
	}
	
	handleInputChanged(event, name) {
		const target = event.target;
		var value = target.type === 'checkbox' ? target.checked : target.value;
		var formData = this.state.formData;
		
		formData[name] = value;
		
		this.setState({
		  formData: formData,
		});
	}
	
	
	onSave(event) {
		event.preventDefault();
		const validation = validateForm(this.state.formData, validationFieldsYearlyBudget);
		
		// IF FORM IS NOT VALIDATED, THEN SET STATE ACCORDINGLY
		if(!validation.result)
		{
			showFormValidationAlert(validation.errors);
			this.setState({
				errors: validation.errors
			});
		}
		else {
			var yearlyBudget = this.state.formData;
			this.props.onSave(yearlyBudget);
		}
	}
	
	render() {
		const vendorsListMapped = mapArrayToObject(this.props.vendorsList, 'id', 'name');
		const loggedInUser = getLoggedInUser();
		var content = (<Form className='form-page' horizontal>
			<Row>
				<Col md={2}></Col>
				<Col md={8}>
					<InputText label={<span><Translate value="components.labels.year" /></span>} 
						id={"year"} name={"year"} action={this.handleInputChanged} 
						value={this.state.formData.year} 
						errMsg={this.state.errors.year}
					/>
					<InputText label={<span><Translate value="components.labels.salesGoal" /> ({getCurrencySymbol(Config.locale)})</span>} 
						id={"totalSalesGoal"} name={"totalSalesGoal"} action={this.handleInputChanged} 
						value={this.state.formData.totalSalesGoal} 
						errMsg={this.state.errors.totalSalesGoal}
					/>
					<InputText label={<span><Translate value="components.labels.expenseBudget" /> ({getCurrencySymbol(Config.locale)})</span>} 
						id={"totalExpenseBudget"} name={"totalExpenseBudget"} action={this.handleInputChanged} 
						value={this.state.formData.totalExpenseBudget} 
						errMsg={this.state.errors.totalExpenseBudget}
					/>
					<InputText label={<span><Translate value="components.labels.prospectCallsPerMonth" /> </span>} 
						id={"prospectCallsPerMonth"} name={"prospectCallsPerMonth"} action={this.handleInputChanged} 
						value={this.state.formData.prospectCallsPerMonth} 
						errMsg={this.state.errors.prospectCallsPerMonth}
					/>
				</Col>
				<Col md={2}></Col>
			</Row>
		</Form>);
				
		return(<Modal show={this.props.visible} >
			<Modal.Header>
				<Modal.Title>{this.state.title}</Modal.Title>
				<Modal.Body>
					{content}
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={this.props.onClose}><Translate value="components.buttons.close" /></Button>
					<Button onClick={this.onSave} bsStyle="primary" disabled={loggedInUser.accessComponentsCode == 'V'}><Translate value="components.buttons.saveChanges" /></Button>
				</Modal.Footer>
			</Modal.Header>
		</Modal>);
	}
	
}


const validationFieldsMonthlyExpenditure = {
	year: "required,numeric",
	month: "required,numeric",
};

const validationFieldsMonthlyProspectCall = {
	year: "required,numeric",
	month: "required,numeric",
	numCalls: "required,numeric",
};


const expenditureCategories = {
	[constants.expenditure_category_airline]: "Airline",
	[constants.expenditure_category_lodging]: "Loding",
	[constants.expenditure_category_restaurant]: "Restaurant",
	[constants.expenditure_category_car_rental]: "Rental",
	[constants.expenditure_category_auto_expense]: "Auto Expense",
	[constants.expenditure_category_parking]: "Parking",
	[constants.expenditure_category_cellphone]: "Cellphone",
	[constants.expenditure_category_misc]: "Miscellaneous",
};

const months = {
	1: "January",
	2: "February",
	3: "March",
	4: "April",
	5: "May",
	6: "June",
	7: "July",
	8: "August",
	9: "September",
	10: "October",
	11: "November",
	12: "December",
};

class MonthlyExpenditureForm extends Component
{
	constructor(props) {
		super(props);
		
		this.state = {
			title: "Add New Monthly Expenditure",
			errors: resetObject(validationFieldsMonthlyExpenditure),
			formData: {
				year: (new Date()).getFullYear(),
				month: (new Date()).getMonth() + 1,
				monthName: months[(new Date()).getMonth() + 1],
				amount: null,
				expenditureCategoryLookupId: null,
				expenditureCategoryName: null,
				id:0,
				model:"com.phototype.myinkiq.model.vos.dtos::MonthlyExpendituresDto",
				retired:false,
				userId:null,
			},
			expenditures: [],
		};
		
		this.handleInputChanged = this.handleInputChanged.bind(this);
		this.onSave = this.onSave.bind(this);
	}
	
	componentDidMount() {
		const userId = this.props.userId;
		// console.log("userId: ", this.props.userId, ", this.props.editExpenditureRow: ", this.props.editExpenditureRow);
		let formData = this.state.formData;
		
		/////////////// CHECKING FOR EDIT MODE //////////////////
		
		let month = this.state.formData['month'];
		let year = this.state.formData['year'];
		
		if(this.props.editExpenditureRow) { // If this is not null
			let editExpenditureRow = this.props.editExpenditureRow;
			
			month = editExpenditureRow['month'];
			year = editExpenditureRow['year'];

		}
		
		// Fetch existing Expenditures
		
		formData.userId = userId;
		formData.month = month;
		formData.year = year;
		
		getAPI("expenditures/" + userId + "/existing?month=" + month + "&year=" + year).then(resExpenditures => {
			// console.log("resExpenditures: ", resExpenditures);
			const expenditures = resExpenditures['data'].map(obj => {
				return ({
					id: obj[0], 
					expenditureCategoryLookupId: obj[1],
					amount: obj[2],
					expenditureName: obj[3],
				})
			});
			this.setState({
				formData: formData,
				expenditures: expenditures,
			});
		});
		
	}
	
	handleInputChanged(event, name) {
		// console.log("handleInputChanged: name: ", name);
		const target = event.target;
		var value = target.type === 'checkbox' ? target.checked : target.value;
		var formData = this.state.formData;
		
		if(name.indexOf('category') >= 0) {
			const categoryLookupId = name.replace("category", "");
			
			var expenditures = this.state.expenditures;
			var row = expenditures.filter(obj => obj.expenditureCategoryLookupId == categoryLookupId)[0];
			row['amount'] = value;
			row['bHasChanged'] = true;
			// console.log("expenditures: ", expenditures);
			this.setState({
			  expenditures: expenditures,
			});
			
		} else {
			switch(name) {
				case 'expenditureCategoryLookupId':
					formData['expenditureCategoryName'] = expenditureCategories[value];
					break;
				
				case 'month':
					formData['monthName'] = months[value];
					break;
			}
			
			formData[name] = value;
			
			this.setState({
			  formData: formData,
			});
		}
	}
	
	
	onSave(event) {
		event.preventDefault();
		const validation = validateForm(this.state.formData, validationFieldsMonthlyExpenditure);
		
		// IF FORM IS NOT VALIDATED, THEN SET STATE ACCORDINGLY
		if(!validation.result)
		{
			showFormValidationAlert(validation.errors);
			this.setState({
				errors: validation.errors
			});
		}
		else {
			var monthlyExpenditure = this.state.formData;
			
			const numExpenditures = this.state.expenditures.filter(obj => obj.bHasChanged == true).length;
			// console.log("numExpenditures: ", numExpenditures);
			let numSaves = 0;
			// Save All monthly expenditures per category
			this.state.expenditures.forEach((expenditure, i) => {
				if(expenditure['bHasChanged']) {
					// Make dto
					var dto = {
						id: expenditure.id,
						userId: this.state.formData.userId,
						month: this.state.formData.month,
						year: this.state.formData.year,
						expenditureCategoryLookupId: expenditure.expenditureCategoryLookupId,
						amount: expenditure.amount,
						model:"com.phototype.myinkiq.model.vos.dtos::MonthlyExpendituresDto",
						retired:false,
					}
					// save
					postAPI("expenditures/", dto).then(resmonthlyExpenditure => {
						numSaves++;
						if(numSaves >= numExpenditures)
							this.props.onSave(monthlyExpenditure);
					});
				}
			});
			
		}
	}
	
	render() {
		
		const expenditureCategoriesContent = this.state.expenditures.map((expenditure, i) => 
			<span>
				<InputText label={expenditure.expenditureName} 
					id={"category" + expenditure.expenditureCategoryLookupId} name={"category" + expenditure.expenditureCategoryLookupId} action={this.handleInputChanged} 
					value={expenditure.amount} 
				/>
			</span>
		);
		
		const loggedInUser = getLoggedInUser();
		var content = (<Form className='form-page' horizontal>
			<Row>
				<Col md={2}></Col>
				<Col md={8}>
					<InputText label={<span><Translate value="components.labels.year" /></span>} 
						id={"year"} name={"year"} action={this.handleInputChanged} 
						value={this.state.formData.year} 
						errMsg={this.state.errors.year}
					/>
					<DataDropdown required 
						label={<Translate value='components.labels.month' />} data={months} 
						name='month' value={this.state.formData.month} 
						action={this.handleInputChanged} 
						errMsg={this.state.errors.month}
					/>
					{expenditureCategoriesContent}
				</Col>
				<Col md={2}></Col>
			</Row>
		</Form>);
				
		return(<Modal show={this.props.visible} >
			<Modal.Header>
				<Modal.Title>{this.state.title}</Modal.Title>
				<Modal.Body>
					{content}
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={this.props.onClose}><Translate value="components.buttons.close" /></Button>
					<Button onClick={this.onSave} bsStyle="primary" disabled={loggedInUser.accessComponentsCode == 'V'}><Translate value="components.buttons.saveChanges" /></Button>
				</Modal.Footer>
			</Modal.Header>
		</Modal>);
	}
	
}

class MonthlyProspectCallForm extends Component
{
	constructor(props) {
		super(props);
		
		this.state = {
			title: "Add New Monthly Prospect Call",
			errors: resetObject(validationFieldsMonthlyProspectCall),
			formData: {
				year: (new Date()).getFullYear(),
				month: (new Date()).getMonth() + 1,
				monthName: months[(new Date()).getMonth() + 1],
				numCalls: null,
				id:0,
				model:"com.phototype.myinkiq.model.vos.dtos::MonthlyProspectCallsDto",
				retired:false,
				userId:null,
			},
			ProspectCalls: [],
		};
		
		this.handleInputChanged = this.handleInputChanged.bind(this);
		this.onSave = this.onSave.bind(this);
	}

	componentDidMount() {
		/////////////// CHECKING FOR EDIT MODE //////////////////
		if(this.props.editProspectCallRow) { // If this is not null
			let editProspectCallRow = this.props.editProspectCallRow;
			editProspectCallRow['userId'] = this.props.userId;
			// console.log("userId: ", this.props.userId);
			// retired must be false by default
			if(editProspectCallRow['retired'] == null)
				editProspectCallRow['retired'] = false;
			this.setState({
				formData: editProspectCallRow,
			});
		}
		
	}

	handleInputChanged(event, name) {
		const target = event.target;
		var value = target.type === 'checkbox' ? target.checked : target.value;
		var formData = this.state.formData;
				
		formData[name] = value;
		switch(name) {
			case 'month':
				formData['monthName'] = months[value];
				break;
		}

		this.setState({
		  formData: formData,
		});
	}
	


	onSave(event) {
		event.preventDefault();
		const validation = validateForm(this.state.formData, validationFieldsMonthlyProspectCall);
		
		// IF FORM IS NOT VALIDATED, THEN SET STATE ACCORDINGLY
		if(!validation.result)
		{
			showFormValidationAlert(validation.errors);
			this.setState({
				errors: validation.errors
			});
		}
		else {
			var prospectCall = this.state.formData;
			this.props.onSave(prospectCall);
		}
	}

	render() {
				
		const loggedInUser = getLoggedInUser();
		var content = (<Form className='form-page' horizontal>
			<Row>
				<Col md={2}></Col>
				<Col md={8}>
					<InputText label={<span><Translate value="components.labels.year" /></span>} 
						id={"year"} name={"year"} action={this.handleInputChanged} 
						value={this.state.formData.year} 
						errMsg={this.state.errors.year}
					/>
					<DataDropdown required 
						label={<Translate value='components.labels.month' />} data={months} 
						name='month' value={this.state.formData.month} 
						action={this.handleInputChanged} 
						errMsg={this.state.errors.month}
					/>
					<InputText label={<span><Translate value="components.labels.numCalls" /></span>} 
						id={"numCalls"} name={"numCalls"} action={this.handleInputChanged} 
						value={this.state.formData.numCalls} 
						errMsg={this.state.errors.numCalls}
					/>
				</Col>
				<Col md={2}></Col>
			</Row>
		</Form>);
				
		return(<Modal show={this.props.visible} >
			<Modal.Header>
				<Modal.Title>{this.state.title}</Modal.Title>
				<Modal.Body>
					{content}
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={this.props.onClose}><Translate value="components.buttons.close" /></Button>
					<Button onClick={this.onSave} bsStyle="primary" disabled={loggedInUser.accessComponentsCode == 'V'}><Translate value="components.buttons.saveChanges" /></Button>
				</Modal.Footer>
			</Modal.Header>
		</Modal>);
	}

}